import React from 'react';
import { Box, Flex } from 'theme-ui';

import IFrameTemplate from '../iFrameTemplate';
import { GatsbyImage } from 'gatsby-plugin-image';

const IFrameWithMediaTemplate = ({ iframe, media }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
    <Box
      sx={{
        flex: '1 1 0%',
        minWidth: 0,
      }}
    >
      <IFrameTemplate
        {...iframe}
        containerSx={{ py: 0, height: '100%' }}
        scrolling={false}
      />
    </Box>
    {media.gatsbyImageData ? (
      <Box
        sx={{
          width: '35%',
          '.gatsby-image-wrapper': {
            height: '100%',
          },
        }}
      >
        <GatsbyImage image={media.gatsbyImageData} />
      </Box>
    ) : null}
  </Box>
);

export default IFrameWithMediaTemplate;
