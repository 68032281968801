/** @jsx jsx */

import { FC, useState, useEffect, CSSProperties } from 'react';
import { Box, useThemeUI, jsx, ThemeUIStyleObject } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';

type iFrameTemplateProps = {
  iFrameSrc?: string;
  iFrameTitle?: string;
  verticalPadding: string;
  hideOnMobile: boolean;
  hasDynamicHeight?: boolean;
  initialHeight?: number;
  fullWidth?: boolean;
  containerSx?: ThemeUIStyleObject;
  scrolling?: boolean;
};

const VALID_ORIGIN_REGEX = /(.*)\.sprinklr\.com/;

const isFromCorrectOrigin = origin => VALID_ORIGIN_REGEX.test(origin);

const IFrameTemplate: FC<iFrameTemplateProps> = ({
  iFrameTitle,
  verticalPadding,
  hideOnMobile,
  hasDynamicHeight,
  initialHeight,
  fullWidth,
  iFrameSrc,
  containerSx,
  scrolling = true,
}) => {
  const [iframeHeight, setIframeHeight] = useState(initialHeight ?? 0);

  useEffect(() => {
    if (!hasDynamicHeight) {
      return;
    }
    const handler = event => {
      if (
        isFromCorrectOrigin(event.origin) &&
        event.data.type === 'SET_HEIGHT' &&
        event.data.payload?.height
      ) {
        setIframeHeight(event.data.payload.height);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [hasDynamicHeight]);

  const { theme } = useThemeUI();
  const paddingMap = {
    SMALL: [theme.container.sm],
    MEDIUM: [theme.container.sm],
    LARGE: [theme.container.md],
  };
  const py = verticalPadding
    ? paddingMap[verticalPadding]
    : paddingMap['MEDIUM'];

  const iFrameStyle = {
    border: '0',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  };

  const display = hideOnMobile ? ['none', 'block'] : ['block'];

  return (
    <Container
      containerSx={{ py, display, ...containerSx }}
      fullWidth={fullWidth}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          pt: '56.25%',
          ...(hasDynamicHeight ? { height: iframeHeight } : { height: '100%' }),
        }}
      >
        <iframe
          title={iFrameTitle}
          style={iFrameStyle as CSSProperties}
          src={iFrameSrc}
          allowFullScreen
          {...(hasDynamicHeight || !scrolling
            ? { scrolling: 'no' }
            : undefined)}
        />
      </Box>
    </Container>
  );
};
export default IFrameTemplate;
